// extracted by mini-css-extract-plugin
export var resetPassword = "styles-module--resetPassword--1h3hC";
export var resetHeader = "styles-module--resetHeader--1Nav7";
export var backBtn = "styles-module--backBtn--2JhoG";
export var form = "styles-module--form--1-Xah";
export var resetDescription = "styles-module--resetDescription--2wIvQ";
export var edit = "styles-module--edit--2CFl4";
export var fullInput = "styles-module--fullInput--kU188";
export var formCta = "styles-module--formCta--11TR4";
export var resetButton = "styles-module--resetButton--2_Lx9";
export var successModal = "styles-module--successModal--1z1AU";
export var iconWrapper = "styles-module--iconWrapper--OzIlb";