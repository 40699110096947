import React from "react";
import axios from "axios";
import { navigate } from "gatsby";
import { LocaleAsString } from "../../localeEngine";
import { Input } from "../../components/forms";
import Layout from "../../components/layout";
import { Container, Typography, Button } from "../../components/utils";
import Modal from "../../components/modal";
import EditButton from "../../components/checkout/editButton";
import { EMAIL_REGEX } from "../../components/forms/input/regex";
import { CONNECT, CONNECT_CLIENT } from "../../utils/definitions";
import { getSession } from "../../utils/localStorage";
import Icon from "../../components/icons";
import * as styles from "./styles.module.scss";

interface ResetPasswordProps {
  location: any;
}

interface ResetPasswordState {
  form: form;
  loading: boolean;
  step: number;
  successModal: boolean;
}

type form = {
  email: string;
  temporaryPassword: string;
  newPassword: string;
  newPassword2: string;
};

export default class ResetPassword extends React.Component<
  ResetPasswordProps,
  ResetPasswordState
> {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        email:
          this.props.location.state && this.props.location.state.email
            ? this.props.location.state.email
            : "",
        temporaryPassword: "",
        newPassword: "",
        newPassword2: "",
      },
      loading: false,
      step: 1,
      successModal: false,
    };
  }

  handleChange = (name, event) => {
    const currentForm = { ...this.state.form };
    let value = event.target.value;
    currentForm[name] = value;

    this.setState({ form: currentForm });
  };

  getTemporary = () => {
    this.setState({ loading: true }, () => {
      axios
        .post(`${CONNECT}/checkout/temporary-password`, {
          client: CONNECT_CLIENT,
          session: getSession().uuid,
          email: this.state.form.email,
        })
        .catch(() => console.log("ERROR"))
        .then((response) => {
          this.setState({ loading: false, step: 2 });
        });
    });
  };

  getFormByStep = (step) => {
    if (step === 1) return this.getTemporaryForm();
    if (step === 2) return this.getResetForm();
  };

  getTemporaryForm = () => {
    return (
      <>
        <Typography
          variant="h400-medium"
          component={"p"}
          className={styles.resetDescription}
        >
          <LocaleAsString id="reset_password.header.instructions" />
        </Typography>
        <Input
          className={styles.fullInput}
          label={<LocaleAsString id="reset_password.form.email" />}
          icon="Email"
          required
          name="email"
          type="email"
          onChange={(e) => this.handleChange("email", e)}
          value={this.state.form.email}
        />
      </>
    );
  };

  getResetForm = () => {
    return (
      <>
        <Typography
          variant="h300-medium"
          component={"p"}
          className={styles.resetDescription}
        >
          <LocaleAsString id="reset_password.form.submited_temporary_password" />{" "}
          <div className={styles.edit}>
            <strong>{this.state.form.email}</strong>
            <EditButton onClick={() => this.setState({ step: 1 })} />
          </div>
        </Typography>
        <Typography
          variant="h300-medium"
          component={"p"}
          className={styles.resetDescription}
        >
          <LocaleAsString id="reset_password.form.submited_temporary_password_explain" />
        </Typography>
        <Input
          className={styles.fullInput}
          label={<LocaleAsString id="reset_password.form.temporary_password" />}
          icon="Clock"
          required
          name="temporaryPassword"
          type="text"
          onChange={(e) => this.handleChange("temporaryPassword", e)}
          value={this.state.form.temporaryPassword}
        />
        <Typography
          variant="h300-medium"
          component={"p"}
          className={styles.resetDescription}
        >
          <LocaleAsString id="reset_password.form.enter_a_new_password" />
        </Typography>
        <Input
          className={styles.fullInput}
          label={<LocaleAsString id="reset_password.form.new_password" />}
          icon="Lock"
          required
          name="newPassword"
          type="password"
          onChange={(e) => this.handleChange("newPassword", e)}
          value={this.state.form.newPassword}
          autoComplete="new-password"
        />
        <Input
          className={styles.fullInput}
          label={
            <LocaleAsString id="reset_password.form.repeat_new_password" />
          }
          icon="Lock"
          required
          name="newPassword2"
          type="password"
          repeatField={
            this.state.form.newPassword !== this.state.form.newPassword2
          }
          disablePaste={true}
          repeatError="repeatPassword"
          onChange={(e) => this.handleChange("newPassword2", e)}
          value={this.state.form.newPassword2}
          autoComplete="new-password"
        />
      </>
    );
  };

  changePassword = () => {
    this.setState({ loading: true }, () => {
      axios
        .post(`${CONNECT}/checkout/change-password`, {
          client: CONNECT_CLIENT,
          session: getSession().uuid,
          email: this.state.form.email,
          temporaryPassword: this.state.form.temporaryPassword,
          password: this.state.form.newPassword,
        })
        .catch(() => console.log("ERROR"))
        .then((response) => {
          this.setState({ successModal: true });
        });
    });
  };

  checkInputs = () => {
    const { email, temporaryPassword, newPassword, newPassword2 } =
      this.state.form;

    if (this.state.step === 1) {
      return !EMAIL_REGEX.test(email);
    } else if (this.state.step === 2) {
      return !(
        temporaryPassword &&
        newPassword &&
        newPassword2 &&
        newPassword === newPassword2
      );
    }

    return true;
  };

  onSubmit = (e) => {
    if (!this.checkInputs()) {
      this.state.step === 1 ? this.getTemporary() : this.changePassword();
    }
    e.preventDefault();
  };

  render() {
    const {
      state,
      onSubmit,
      getTemporary,
      getFormByStep,
      changePassword,
      checkInputs,
    } = this;
    const { loading, step, successModal } = state;

    return (
      <Layout
        backgroundColor={"#fff"}
        backgroundColorDesktop={"#f4f4f9"}
        hideMobileFooter={true}
      >
        <Container
          maxWidth="lg"
          component="section"
          className={styles.resetPassword}
        >
          <div className={styles.resetHeader}>
            <Button
              className={styles.backBtn}
              onClick={() =>
                step === 1 ? navigate("/sign-in") : this.setState({ step: 1 })
              }
            >
              <Icon icon="ArrowLeft" size="Small" />
            </Button>
            <Typography variant="h500-medium" component={"span"}>
              <LocaleAsString id="reset_password.header.reset_password" />
            </Typography>
          </div>

          <form className={styles.form} onSubmit={onSubmit}>
            {getFormByStep(step)}
            <input type="submit" style={{ display: "none" }} />
          </form>
          <div className={styles.formCta}>
            <Button
              disabled={checkInputs()}
              className={styles.resetButton}
              loading={loading}
              onClick={() => (step === 1 ? getTemporary() : changePassword())}
            >
              <Typography component="span" variant="h400-medium">
                {step === 1 ? (
                  <LocaleAsString id="reset_password.submit.continue" />
                ) : (
                  <LocaleAsString id="reset_password.submit.confirm" />
                )}
              </Typography>
            </Button>
          </div>
        </Container>
        {successModal && (
          <Modal
            closeFn={() => this.setState({ successModal: false })}
            isAlert={true}
            size="small"
          >
            <div className={styles.successModal}>
              <span className={styles.iconWrapper}>
                <Icon icon="CheckCircle" size="Small" />
              </span>
              <Typography component="span" variant="h400-heavy">
                {/* Your password has been reset */}
                Ditt nye passord er bekreftet
              </Typography>
              <Button
                className={styles.resetButton}
                onClick={() =>
                  navigate("/sign-in", {
                    state: {
                      email: this.state.form.email,
                    },
                  })
                }
              >
                <Typography component="span" variant="h400-medium">
                  {/* Continue */}
                  Fortsett
                </Typography>
                <Icon icon="ArrowRight" size="Small" />
              </Button>
            </div>
          </Modal>
        )}
      </Layout>
    );
  }
}
